import React from 'react';
import Slider from 'infinite-react-carousel'
import Grid from '@material-ui/core/Grid';
import TweenOne from 'rc-tween-one';
import './Slider.css'

export default class SliderBootstrapStatic extends React.Component {
  render() {
    const settings =  {
      autoplay: true,
      dots: true,
      duration: 150
    };
    return (
      <div>
        <Slider { ...settings }>
          <div  className="jumbotron slider_2 jumbotron-12">
             <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
                className="slider-height-static"
              >
                
                <TweenOne className="banner-user-text" 
                      animation={{ y: 30, opacity: 0, type: 'from', delay: 100 }}
                    >
                  <h3 class="white-text  slider-title">{this.props.title}</h3>
                </TweenOne>
              </Grid>
          </div>
        </Slider>
      </div>
    );
  }
}